<template>
  <identify-register :typePage="typePage"></identify-register>
</template>

<script>
import IdentifyRegister from "@/views/modules/collection/business/collIdentification/identifyRegister.vue";

export default {
    name: "identifyInventory",
    components: {IdentifyRegister},
    data(){
        return{
            typePage:'identifyInventory',
        }
    },
}
</script>

<style scoped>

</style>